import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
export default {
  name: "AppLoading",
  components: {
    Loading: function Loading() {
      return import("@/components/Loading");
    }
  }
};